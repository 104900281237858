/* For WebKit browsers */

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #727272;
}

body::-webkit-scrollbar-thumb:active,
body *::-webkit-scrollbar-thumb:active {
  background-color: #727272;
}

body::-webkit-scrollbar-thumb:hover,
body *::-webkit-scrollbar-thumb:hover {
  background-color: #727272;
}

/* For Firefox */
body,
body * {
  scrollbar-width: auto;
  scrollbar-color: #727272 white;
}
